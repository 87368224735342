@import 'src/layout/assets/css/vars';


.facebook-share-button {

  $facebook-color: #3b5998;

  align-items: center;
  background-color: $facebook-color;
  border: solid 1px $facebook-color;
  border-radius: 20px;

  color: $text-white-color-code;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  padding: 10px 30px 10px 31px;
  width: 140px;

  .fb-label {
    font-size: $text-m;
    font-weight: 600;
    line-height: 100%;
    margin-left: $std-light-spacing;
  }


}
