@import 'src/layout/assets/css/vars';

.infinite-loading {

  color: $color1;
  font-size: $text-m;
  font-weight: 600;
  margin-top: $std-lg-spacing;
  text-align: center;
  width: 100%;

}
