@import 'src/layout/assets/css/vars';

.bottom-banner {

  background-color: $color0;
  box-shadow: $bottom-shadow-code;
  display: flex;
  justify-content: space-between;
  padding: $std-lg-spacing;
  position: relative;
  width: 100%;
  z-index: 1000;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }

  .left {

    .logo {
      height: 42px;
    }

    .txt-light {
      color: $text-light-color;
      font-size: $text-m;
      margin-top: $std-top-spacing;
    }

    .txt-medium {
      color: $text-regular-color;
      font-size: $text-m;
      margin-top: $std-top-spacing;
    }
  }

  .center,
  .right {

    @media only screen and (max-width: 768px) {
      margin-top: $std-top-spacing;
    }

    .title {
      color: $text-dark-color-code;
      font-size: $text-m;
      margin-bottom: $light-top-spacing;
      //text-transform: uppercase;
      font-weight: bold;

    }

    .link,
    .link a {
      color: $text-regular-color;
      font-size: $text-m;
      margin-bottom: 25px;
      margin-top: 25px;

      &:hover {
        color: $color1;
      }

    }

    .contact-items {
      display: flex;

      @media only screen and (max-width: 768px) {
        flex-direction: column;
      }

    }

    .contact-block {
      align-items: center;
      display: flex;
      margin-bottom: $std-top-spacing;
      margin-right: $std-side-spacing;

      .icon {
        align-items: center;
        border: 1px solid $color3;
        border-radius: $base-border-radius;
        color: $color3;
        display: flex;
        height: 38px;
        justify-content: center;
        margin-right: $std-spacing;
        width: 38px;
      }


      .data .label {
        font-size: $text-m;
        font-weight: 500;
      }

      .data .value {
        color: $text-regular-color;
        font-size: $text-m;
      }


    }

  }


}
