@import 'src/layout/assets/css/vars';

@media only screen and (max-width: 768px) {
  .box {
    padding: $std-top-spacing $mobile-side-spacing;

    &-title {
      margin: calc(#{$std-top-spacing} * -1) calc(#{$mobile-side-spacing} * -1) $std-top-spacing;
      padding: 15px $mobile-side-spacing;
    }

    &-bottom {
      margin: $std-top-spacing calc(#{$mobile-side-spacing} * -1) 0;
      padding: 15px $mobile-side-spacing;
    }

    &-options {
      right: 10px;
      top: 12px;
    }
  }

}
