


$color0-code: #fff;
$color3-code: #86b049;
$color2-code: #ecf6ff;
$color1-code: #1f8f3d;
$color4-code: #d8d8d8;
$color5-code: #f9f9f9;
$color6-code: #afafaf;
$color7-code: #275e39;
$color8-code: #d98c21;
$color9-code: #eb6609;
$color10-code: #a5a5a5;
$color11-code: #343a40;
$color12-code: #85b4db;
$color13-code: #dd9ad5;
$color14-code: #5e5e5e;
$color101-code: $color1-code;
$color100-code: #c91010; // For erro$color9rs
$color102-code: #fff3cd;
$color1000-code: #c91010; // For errors
$menu-bg-color: rgb(0, 0, 0, .3);
$label-background-color: rgba(0, 0, 0, .4);
$background-color-code: #f1f1f1;
$box-content-color-code: #fff;
$box-content-alt-color-code: #f8f8f8;
$border-box-color-code: #d8d8d8;
$form-background-color-code: transparent;
$form-border-color-code: #ccc;
$form-base-color-code: $color1-code;
$form-select-color-code: $color1-code;
$form-select-text-color-code: #fff;

$text-regular-color-code: #5d5d5d;
$text-light-color-code: #8d8d8d;
$text-dark-color-code: #303030;
$text-white-color-code: #fff;

$menu-max-width-mobile: 992px;

$shadow-color: rgba(107, 107, 107, .25);
$box-shadow-code: 0 4px 20px 0 $shadow-color;
$bottom-shadow-code: 0 -2px 20px 0 $shadow-color;
$light-shadow: $box-shadow-code;


$color0: var(--color-0);
$color1: var(--color-1);
$color2: var(--color-2);
$color3: var(--color-3);
$color4: var(--color-4);
$color5: var(--color-5);
$color6: var(--color-6);
$color7: var(--color-7);
$color9: var(--color-9);
$color10: var(--color-10);
$color11: var(--color-11);
$color101: var(--color-101);
$color100: var(--color-100);
$color102: var(--color-102);
$color1000: var(--color-1000);

$background-color: var(--background-color);
$box-content-color: var(--box-content-color);
$box-content-alt-color: var(--box-content-alt-color);
$border-box-color: var(--border-box-color);
$form-background-color: var(--form-background-color);
$form-border-color: var(--form-border-color);
$form-base-color: var(--form-base-color);
$form-select-color: var(--form-select-color);
$form-select-text-color: var(--form-select-text-color);
$background-layer: var(--background-layer);

$box-shadow: var(--box-shadow);

$text-white-color: var(--text-white-color);
$text-dark-color: var(--text-dark-color);
$text-regular-color: var(--text-regular-color);
$text-light-color: var(--text-light-color);

$scroll-color: var(--scroll-color);
$scroll-bg-color: var(--scroll-bg-color);


//Font Sizes
$text-xs: 12px;
$text-s: 13px;
$text-m: 14px;
$text-l: 16px;
$text-xl: 20px;
$text-xll: 25px;


//Padding & Margin
$std-spacing: 20px;
$std-light-spacing: 10px;
$std-lg-spacing: 40px;

$std-top-spacing: 20px;
$light-top-spacing: 10px;
$lg-top-spacing: 30px;


$std-side-spacing: 30px;
$mobile-side-spacing: 7px;

//Radius
$radius-input-round: 15px;
$radius-button-round: 20px;
$base-border-radius: 8px;
$light-radius: 10px;
