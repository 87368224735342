@import 'src/layout/assets/css/vars';

.search-control {
  margin-right: $std-spacing;
  max-width: 340px;
  min-width: 210px;

}

.search-area {

  margin-bottom: 0;
  white-space: nowrap;

  .search-area-bar {
    display: flex;
  }

  .search-area-filters {
    font-size: $text-s;
    margin: $light-top-spacing calc(#{$std-side-spacing} * -1) calc(#{$light-top-spacing} * -.8);
    padding: $std-top-spacing $std-side-spacing;
    width: 100%;

    form {
      .btn-primary {
        float: right;
        margin-top: $std-top-spacing;
      }
    }


  }

}
