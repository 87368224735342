@import 'src/layout/assets/css/vars';

@media only screen and (max-width: 580px) {

  .search-control {
    margin-right: $std-spacing;
    max-width: 100%;
    min-width: 150px;

  }


}
