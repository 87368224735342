@import 'src/layout/assets/css/vars';


.top-banner {

  background: url('banner.png') center center no-repeat;
  background-size: cover;

  color: $text-white-color-code;
  display: flex;
  flex-direction: column;
  min-height: 400px;
  width: 100%;

  &.cat {
    background: url('cat.png') center center no-repeat;
    background-size: cover;
  }

  &.dog {
    background: url('Dog.png') center center no-repeat;
    background-size: cover;

  }


  &.small {
    min-height: 300px;
  }

  .go-back {
    align-items: center;
    background-color: $color0;
    border-bottom-right-radius: $light-radius;
    border-top-right-radius: $light-radius;
    color: $text-dark-color;
    cursor: pointer;
    display: flex;
    font-size: 22px;
    height: 50px;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 80px;
    width: 40px;

    &:hover {
      color: $color1;
    }

  }

  .menu {
    align-items: center;
    //background-color: $menu-bg-color;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: $std-top-spacing;
    margin-top: 30px;
    text-transform: uppercase;

    .logo {
      filter: brightness(0) invert(1);
      height: 60px;
      margin-left: $std-lg-spacing;
      margin-right: $std-lg-spacing;
    }

    .links {
      margin-left: 10px;

      a {
        color: $color0;
        font-size: 14px;
        font-weight: 400;
        line-height: 60px;
        margin-right: 40px;

        &.active {
          text-decoration: underline;
          text-underline-offset: 8px;

        }
      }
    }
  }


  .banner-body {
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    justify-content: center;
    max-width: 850px;
    padding: $std-side-spacing;

    .main-text {
      font-size: $text-xll;
      font-weight: bold;
    }

    .title {
      font-size: $text-xll;
      font-weight: 550;
      margin-left: $std-lg-spacing;
      text-shadow: 0 2px 10px $label-background-color;

    }

    .sec-text {
      font-size: $text-xl;
      margin-top: $std-top-spacing;
    }
  }


}

@media only screen and (max-width: 600px) {
  .top-banner {

    .burger {
      display: block;
      height: 24px;
      position: absolute;
      right: $std-light-spacing;
      top: 15px;
      width: 24px;
    }

    .menu {

      flex-direction: column;
      margin-top: unset;
      min-height: 50px;
      padding-top: $std-light-spacing;

      .links {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: unset;
        margin-top: unset;

        width: 100%;

        a {
          margin: 10px;
          text-align: center;
        }
      }

    }

    .sec-text {
      display: none;
    }


  }


}
