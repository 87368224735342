.input-wrapper {
  .rah-input-wrapper {
    width: 100%;

    .rah-hint-wrapper {
      padding-top: 11px;

      .rah-hint {
        height: 15px;
        line-height: 1.5;
        padding: 1.15rem .75rem;
      }
    }
  }
}
