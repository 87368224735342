@import './vars';


%no-select {
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

//In order to remove auto fill color from firefox
input {
  filter: none;
}
