@import '../vars';
@import '../generic';


//Colors Overload
$font-family-sans-serif: Heebo, Arial, Helvetica, sans-serif;
$font-family-base: $font-family-sans-serif !default;

$link-decoration: none;
$link-color: none;
$link-hover-color: $color1-code;
$btn-link-color: $color1-code;
$btn-link-hover-color: $color1-code;
$body-bg: $background-color;

$btn-close-focus-shadow: unset;
$btn-focus-box-shadow: none;

$table-hover-bg: $box-content-alt-color-code;
$table-border-color: $box-content-alt-color-code;
$table-group-separator-color: $box-content-alt-color-code;

$input-font-size: $text-m;
$input-bg: $color0-code;
$input-border-color: $form-border-color-code;
$input-group-addon-bg: $color0-code;
$input-group-addon-color: $form-border-color-code;
$enable-shadows: false;
$input-focus-box-shadow: unset;
$input-border-radius: 2px;
$input-focus-border-color: $color1-code;

$primary: $color1-code;
$info: $color3-code;
$danger: $color100-code;
$warning: $color9-code;
$bg-primary: $color0-code;
$success: $color101-code;
$secondary: $color0-code;

$body-bg: $background-color-code;

$min-contrast-ratio: 2.5;


@import 'node_modules/bootstrap/scss/bootstrap';
@import './forms';
@import './pagination';
@import './button';
