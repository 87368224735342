@import 'src/layout/assets/css/vars';

.animal-resume {
  .title {
    color: $text-light-color;
    font-size: $text-s;
    margin-top: 5px;
  }

  .value {
    color: $text-m;
    font-size: $text-m;
  }

  .gender-icon {
    font-size: 25px;

    @media only screen and (max-width: 576px) {
      position: absolute;
      top: $std-light-spacing;
      right: $std-light-spacing;
    }

    &.male {
      color: $color12-code;
    }

    &.female {
      color: $color13-code;
    }
  }


}
