@import 'src/layout/assets/css/vars';

.loading {

  $loading-background: rgba(255, 255, 255, 1);
  $loading-background-mobile: rgba(255, 255, 255, .9);
  $loading-shadow: 0 4px 20px 0 rgba(17, 17, 17, .25);

  align-items: center;
  background: $loading-background;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 4000;

  @media only screen and (max-width: $menu-max-width-mobile) {

    background-color: $loading-background-mobile;
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;

  }

  .loader-container {

    background-color: $color0;
    border-radius: 50%;
    box-shadow: $loading-shadow;
    padding: 20px;

    .loader {

      align-items: center;
      animation: spin 2s linear infinite;
      background-color: $color0;
      border-bottom: 5px solid $background-color;
      border-left: 5px solid $background-color;
      border-radius: 50%;
      border-right: 5px solid $background-color;
      border-top: 5px solid $color1;
      display: flex;
      height: 120px;
      justify-content: center;
      padding: 10px;
      width: 120px;

      svg {
        animation: spin-fixed 2s linear infinite;
      }

    }

    @keyframes spin-fixed {
      0% {
        transform: rotate(360deg);
      }

      100% {
        transform: rotate(0deg);
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }
}

