@import 'src/layout/assets/css/vars';

.dropzone {

  background-color: $background-color;
  position: relative;

  .drop-container {

    background-color: $background-color;
    border: 2px dashed $color4;
    width: 100%;

    &:hover:not(.active):not(:focus) {
      background-color: rgba($color1, .2);
    }

    &.active,
    &:focus {
      background-color: rgba($color1, .2);
      outline: rgba($color1, .1);
    }

    &.active {
      .dropzone-area .dropzone-text {
        visibility: hidden;
      }

      .drop-now {
        display: flex;
      }
    }


  }

  .drop-now {
    align-items: center;
    color: $color1;
    display: none;
    font-size: $text-m;
    height: 100%;
    justify-content: center;
    position: absolute;
    text-align: center;
    width: 100%;

  }

  .dropzone-area {

    color: $color1;
    text-align: center;
    white-space: pre-wrap;


    .dropzone-text {

      color: $text-regular-color;
      display: flex;

      flex-flow: column;
      height: 100%;
      justify-content: center;

      padding: $std-spacing;
      width: 100%;

      > div:not(:first-child) {
        padding-top: $std-top-spacing;
      }

      svg {
        color: $color1;
        display: block;
        height: 30px;
        margin-left: auto;
        margin-right: auto;

        text-align: center;
        width: 100px;

        @media only screen and (max-width: 576px) {
          display: none;
        }


      }
    }
  }
}
