@import 'src/layout/assets/css/vars';





.animal-card-label {
  align-items: center;
  align-self: flex-end;
  background-color: $label-background-color;
  border-radius: 15px;
  display: flex;
  font-size: $text-xs;
  font-weight: 300;
  padding: 2px 10px 2px 5px;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 4;

  .icon {
    margin-right: 3px;
  }

  &.dead {
    color: $text-white-color-code;
  }

  &.pending,
  &.lost,
  &.found {
    color: $text-white-color-code;
  }

}
