@import 'src/layout/assets/css/vars';


.box-step {
  align-items: center;
  background-color: $background-color;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: $std-spacing;
  max-width: 400px;
  padding: $std-spacing;

  .step {
    color: $color1;
    font-size: 35px;
    font-weight: 700;
  }

  .step-title {
    color: $color1;
    font-size: 18px;
    font-weight: 700;
    margin: 0 0 $std-top-spacing;

  }

  .step-content {
    color: $text-regular-color;
  }
}

