@import 'src/layout/assets/css/vars';
@import 'src/layout/assets/css/generic';


:root {
  --rdp-accent-color: #{$form-base-color};
  --rdp-accent-color-dark: #{$form-base-color};
  --rdp-background-color: #{$form-base-color};
  --rdp-cell-size: 35px;
  --rdp-color-secondary: #{$color2};
  --rdp-outline: #{$form-base-color};
}



.form-group:focus-within {

  .date-picker {

    .input-group-text,
    .date-picker-clear {
      color: $form-base-color;
    }

    .date-picker-calendar {
      color: $text-regular-color;
    }
  }
}


.date-picker {

  color: $text-regular-color;

  .empty-value {
    width: 100%;
  }

  .date-picker-separator {
    background-color: $form-border-color;
    margin: 6px 0px;
    width: 1px;
  }

  .date-picker-clear {
    cursor: pointer;
    font-size: 16px;
    position: absolute;
    right: 3rem;
    top: .5rem;
    z-index: 30;

  }

  .date-picker-calendar {
    @extend %no-select;
    background-color: $color0;
    box-shadow: $light-shadow;
    padding: 0 $std-side-spacing;
    position: absolute;
    z-index: 100;

    @media only screen and (max-width: 576px) {
      max-width: calc(100% - 4 * #{$mobile-side-spacing});
      padding: 0 $mobile-side-spacing;
    }
  }

}


.rdp {

  font-size: $text-m;
  text-transform: capitalize;
  width: 100%;

  .rdp-months {
    flex-flow: row wrap;
    justify-content: space-between;
  }

  .rdp-caption {
    font-size: $text-m;

    &_label {
      font-size: $text-l;
      //padding: 0;
    }

  }

  .rdp-nav {

    font-size: 25px;
    right: $std-spacing;
    top: 18px;


    button {
      background-color: unset;
      border: unset;
      color: $form-base-color;
      padding: 0;

      &:focus:not([disabled]),
      &:active:not([disabled]),
      &:hover:not([disabled]) {
        background-color: white;
        color: $form-base-color;
      }


    }
  }


  .rdp-table {

    .rdp-head_cell {
      font-size: $text-s;
    }

    .rdp-button:focus:not([disabled]),
    .rdp-button:active:not([disabled]),
    .rdp-button:hover:not([disabled]) {
      color: $color0;
    }


  }

}

.rdp-dropdown {

  .rdp-caption_label {
    padding: 10px;
  }


  &:focus:not([disabled]) + .rdp-caption_label,
  &:active:not([disabled]) + .rdp-caption_label {
    color: $color0;
  }


}




