@import 'src/layout/assets/css/vars';


$card-size: 300px;
$card-box-margin: 20px;

.animal-card {

  display: block;
  height: $card-size;
  position: relative;
  width: $card-size;

  .not-on-screen {
    background-color: rgba($color14-code, .1);
    border-radius: $radius-input-round;
    height: 100%;
    width: 100%;
  }

  &-photo img,
  &-photo .no-photo {
    border-radius: $radius-input-round;
    box-shadow: $box-shadow;
    height: $card-size;
    width: $card-size;
    z-index: 5;
  }

  &-photo .no-photo {
    align-items: center;
    background-color: $color14-code;
    color: $text-white-color-code;
    display: flex;
    font-size: 20px;
    font-weight: 600;
    justify-content: center;
    text-transform: uppercase;


  }


  &-box {
    background-color: $color0;
    border-radius: $radius-input-round;
    bottom: -40px;
    box-shadow: $box-shadow;
    margin-left: $card-box-margin;
    margin-top: -55px;
    padding: 7px 15px 10px;
    position: absolute;
    width: calc(#{$card-size} - 2 * #{$card-box-margin});


  }

}
