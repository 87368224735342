@import 'src/layout/assets/css/vars';

.info-area {

  background-color: rgba($color3-code, .05);
  border: 1px solid $color3;
  border-radius: $radius-input-round;
  padding: 30px;


  &.lost {
    background-color: rgba($color9-code, .05);
    border: 1px solid $color9-code;
    border-radius: $radius-input-round;

    .title {
      color: $color9;
    }

  }

  .title {
    color: $color3;
    font-size: $text-xll;
    font-weight: 650;
    margin-bottom: 20px;
  }

  .sub-title {
    font-weight: 650;
    margin-top: 20px;
  }


}
