@import 'src/layout/assets/css/vars';

.search-area-filters-badges {

  white-space: pre-wrap;

  .badge {
    border-radius: $radius-input-round;
    color: $color0;
    font-size: $text-xs;
    margin-bottom: 10px;
    margin-right: $std-light-spacing;
    margin-top: $light-top-spacing;
    padding: 10px 15px;

    .filter-label {
      font-weight: 600;

      &::after {
        content: ' | ';
      }
    }

    .filter-value {
      font-weight: normal;
    }

    .filter-remove {
      cursor: pointer;
      margin-left: $std-light-spacing;
    }

  }
}
