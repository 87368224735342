@import 'src/layout/assets/css/vars';

.form-lost {

  padding: $std-spacing;

  .btn-rounded-danger {
    color: $color100;
    cursor: pointer;
  }

  .image {
    text-align: center;

    img {
      max-width: 100%;
    }
  }


  .success-message {
    background-color: rgba($color101-code, .1);
    border: 1px solid $color1;
    color: $color1;
    padding: 30px 40px;
    width: 100%;
  }

  .help {
    font-size: $text-xl;
    font-weight: bold;
    margin-bottom: $std-top-spacing;

  }

}

.view-mode .lost-type-area .lost-type-box {
  width: 100%;

  &:not(.active) {
    display: none;
  }
}

.lost-type-area {

  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }

  .lost-type-box {

    border: 1px solid $form-border-color;
    border-radius: 4px;

    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: $std-spacing;
    width: 48%;

    @media only screen and (max-width: 768px) {
      margin-bottom: $light-top-spacing;
      width: 100%;
    }


    &.found.active,
    &.lost.active {
      box-shadow: $box-shadow;
    }

    &.found:not(.active),
    &.lost:not(.active) {
      opacity: 1;
    }


    &.found.active {
      border: 1px solid $color7;

    }

    &.lost.active {
      border: 1px solid $color100;
    }

    &.lost,
    &.found {

      &.read-only {
        cursor: auto;

        &:not(.active) {
          opacity: .4;
        }
      }

    }

    .label-block {

      align-items: flex-start;
      display: flex;
      flex-direction: column;
      flex-grow: 2;
      justify-content: flex-start;
      padding-left: $std-light-spacing;

      .label {
        font-weight: bold;

        &.lost {
          color: $color100;
        }

        &.found {
          color: $color7;
        }
      }

      .sub-label {
        color: $text-light-color;
        font-size: $text-s;
        text-align: left;
      }
    }

  }
}

.lost-register-form {
  .no-status-set {
    padding-top: $std-top-spacing;
    text-align: center;
    width: 100%;
  }
}

.lost-modal {

  .lost-type-area {
    padding: $std-top-spacing 80px $std-lg-spacing;
  }

  .form-body {
    background-color: $background-color;
    padding: $std-top-spacing 80px;
  }

  .lost-animal-photo .image {
    text-align: center;

    img {
      max-width: 100%;
    }
  }


  .buttons {
    border-top: 1px solid $form-border-color;
    margin-bottom: -40px;
    margin-top: $std-top-spacing;
    padding: $std-top-spacing;
    text-align: right;
  }
}

@media only screen and (max-width: $menu-max-width-mobile) {

  .lost-modal {
    .lost-type-area {
      flex-direction: column;
      padding: $std-top-spacing $mobile-side-spacing;

      .lost-type-box {
        width: 100%;
      }

      > :last-child {
        margin-top: $std-top-spacing;
      }

      .img-block {
        display: none;
      }

    }

    .form-body {
      padding: $std-top-spacing $mobile-side-spacing;
    }
  }


}
