@import 'src/layout/assets/css/vars';

.form-page {
  display: flex;
  justify-content: center;
  margin: $std-top-spacing 0;

  @media only screen and (max-width: 600px) {
    margin: unset;

    .form-lost {
      padding: $std-top-spacing 0;
    }
  }

  &.lost {
    .btn-primary,
    .btn-primary:hover,
    .btn-primary.active,
    .btn-primary:active,
    .btn-primary:focus,
    .btn-primary.focus,
    .btn-primary:active:focus {
      background-color: $color9-code;
      border-color: $color9-code;
      box-shadow: unset;
    }
  }

  &:not(.lost) {
    .btn-primary,
    .btn-primary:hover,
    .btn-primary.active,
    .btn-primary:active,
    .btn-primary:focus,
    .btn-primary.focus,
    .btn-primary:active:focus {
      background-color: $color3-code;
      border-color: $color3-code;
      box-shadow: unset;
    }
  }


  .form-lost {
    max-width: 1400px;

  }
}

.success-message {

  border-radius: 3px;
  color: $text-regular-color;
  font-weight: 300;
  margin: 0 0 $std-top-spacing;
  padding: $std-top-spacing $std-side-spacing;
  text-align: center;
  width: 100%;

  .main-text {
    font-weight: bold;
    margin: $std-top-spacing 0;
  }

  .icon-container {
    align-items: center;
    border: 2px solid $color101-code;
    border-radius: 100%;
    display: flex;
    height: 48px;
    justify-content: center;
    margin: 0 auto;
    width: 48px;

    .icon {
      color: $color101-code;
      height: 26px;
      width: 26px;
    }
  }
}
