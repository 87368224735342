@import 'src/layout/assets/css/vars';
@import 'src/layout/assets/css/utils';

.card-list-container {

  position: relative;
  width: 100%;
}


.card-list {

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;

  @media only screen and (max-width: 576px) {
    justify-content: center;
  }

  .card-container {
    color: unset;
    cursor: pointer;
    margin: 10px 20px 60px 0;

  }

  .animal-resume {

    .title {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .value {
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      display: -webkit-box !important;
      height: 45px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;

    }
  }

}

