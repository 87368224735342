@import 'src/layout/assets/css/vars';

.phone-control.disabled {
  .flag-dropdown {
    display: none;
  }

  .react-tel-input .form-control {
    padding-left: .75rem;
  }
}

.react-tel-input {
  .hide {
    display: none;
  }

  .v-hide {
    visibility: hidden;
  }

  .form-control {
    background: transparent;
    font-size: $text-m;
    outline: none;
    padding: 8px 14px 8px 60px;
    transition: box-shadow ease .25s, border-color ease .25s;

    &:focus {
      outline: 0;
    }

    &.invalid-number {
      border: 1px solid $color100;
    }
  }

  .flag-dropdown {
    border-radius: 3px 0 0 3px;
    bottom: 0;
    padding: 0;
    position: absolute;
    top: 0;

    &:hover,
    &:focus {
      //cursor: pointer;
    }

    &.open {
      z-index: 2;
    }
  }

  input[disabled] + .flag-dropdown:hover {
    //cursor: default;

    .selected-flag {
      background-color: transparent;
    }
  }

  .selected-flag {
    height: 100%;
    outline: none;
    padding: 0 0 0 11px;
    position: relative;
    width: 52px;

    &::before {
      border: 1px solid transparent;
      border-radius: 4px 2px 2px 4px;
      bottom: 2px;
      content: '';
      display: block;
      left: 0;
      position: absolute;
      top: 2px;
      transition: box-shadow ease .25s, border-color ease .25s;
      width: 100%;
    }

    .flag {
      margin-top: -12px;
      position: absolute;
      top: 50%;
    }

    .arrow {
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      border-top: 4px solid $form-border-color;
      height: 0;
      left: 29px;
      margin-top: -1px;
      position: relative;
      top: 50%;
      width: 0;

      &.up {
        border-bottom: 4px solid $form-border-color;
        border-top: 0;
      }
    }
  }

  .country-list {
    background-color: $color0;
    border-radius: 7px;
    box-shadow: 1px 2px 18px $shadow-color;
    list-style: none;
    margin: 10px 0 10px -1px;
    max-height: 220px;
    outline: none;
    overflow-y: scroll;
    padding: 0;
    position: absolute;
    width: 300px;
    z-index: 1;

    .flag {
      display: inline-block;
      left: 13px;
      margin-right: 7px;
      margin-top: 2px;
      position: absolute;
      top: 8px;
    }

    .divider {
      border-bottom: 1px solid $form-border-color;
      margin-bottom: 5px;
      padding-bottom: 5px;
    }

    .country {
      color: $text-regular-color;
      padding: 12px 9px 13px 46px;
      position: relative;

      .dial-code {
        color: $text-light-color;
      }

      &:hover,
      &.highlight {
        background-color: $form-select-color;
        color: $form-select-text-color;

        .dial-code {
          color: $form-select-text-color;
        }

      }
    }

    .country-name {
      margin-right: 6px;
    }

    .search {
      background-color: $color0;
      padding: 10px 0 6px 10px;
      position: sticky;
      top: 0;
      z-index: 2;
    }

    .search-emoji {
      display: none;
      font-size: $text-m;
    }

    .search-box {
      border: 1px solid $form-border-color;
      border-radius: 3px;
      font-size: $text-m;
      line-height: 15px;
      margin-left: 6px;
      outline: none;
      padding: 3px 8px 5px;
    }

    .no-entries-message {
      opacity: .7;
      padding: 7px 10px 11px;
    }
  }

  .invalid-number-message {
    background: $color0;
    color: $color100;
    font-size: 13px;
    left: 25px;
    padding: 0 5px;
    position: absolute;
    top: -7px;
    z-index: 1;
  }

  .special-label {
    background: transparent;
    display: none;
    font-size: 13px;
    left: 25px;
    padding: 0 5px;
    position: absolute;
    top: -7px;
    white-space: nowrap;
    z-index: 1;
  }
}

.dark-mode {
  .react-tel-input {

    .arrow {
      border-top: 4px solid $text-white-color-code;

      &.up {
        border-bottom: 4px solid $text-white-color-code;
      }
    }
  }
}
