@import './vars';

body {
  font-family: 'Heebo-FB', Gotham, Arial, Helvetica, sans-serif !important;
}

@supports (font-variation-settings: normal) {
  body {
    font-family: 'Heebo', Gotham, Arial, Helvetica, sans-serif !important;
  }
}


@font-face {
  font-family: 'Heebo';
  src: url('../fonts/Heebo/Heebo-VariableFont_wght.ttf');
  font-weight: 1 999;
}



@font-face {
  font-family: 'Heebo-FB';
  src: url('../fonts/Heebo/static/Heebo-Regular.ttf');
  font-weight: 400 550;
}


@font-face {
  font-family: 'Heebo-FB';
  src: url('../fonts/Heebo/static/Heebo-SemiBold.ttf');
  font-weight: 580 600;
}


@font-face {
  font-family: 'Heebo-FB';
  src: url('../fonts/Heebo/static/Heebo-Bold.ttf');
  font-weight: 700;
}

