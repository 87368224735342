@import './vars';
@import './generic';

:root {

  --background-color: #{$background-color-code};
  --border-box-color: #{$border-box-color-code};
  --box-content-alt-color: #{$box-content-alt-color-code};
  --box-content-color: #{$box-content-color-code};
  --box-shadow: #{$box-shadow-code};
  --color-0: #{$color0-code};
  --color-1: #{$color1-code};
  --color-10: #{$color10-code};
  --color-100: #{$color100-code};
  --color-1000: #{$color1000-code};
  --color-101: #{$color101-code};
  --color-102: #{$color102-code};
  --color-11: #{$color11-code};
  --color-2: #{$color2-code};
  --color-3: #{$color3-code};
  --color-4: #{$color4-code};
  --color-5: #{$color5-code};
  --color-6: #{$color6-code};
  --color-7: #{$color7-code};
  --color-9: #{$color9-code};
  --form-background-color: #{$form-background-color-code};
  --form-base-color: #{$form-base-color-code};
  --form-border-color: #{$form-border-color-code};
  --form-select-color: #{$form-select-color-code};
  --form-select-text-color: #{$form-select-text-color-code};
  --scroll-bg-color: #{$background-color-code};
  --scroll-color: #{rgba($color10-code,.5)};
  --text-dark-color: #{$text-dark-color-code};
  --text-light-color: #{$text-light-color-code};
  --text-regular-color: #{$text-regular-color-code};

}



body {
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  width: 100%;
}

.fill-empty:empty::before {
  content: '-';
}

.empty-frame {
  min-height: 1200px;
  width: 100%;
}

@import './bootstrap/config';
