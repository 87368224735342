@import 'src/layout/assets/css/vars';

.error-message {

  border-radius: 3px;
  color: $text-regular-color;
  font-weight: 300;
  margin: 0 0 $std-top-spacing;
  padding: $std-top-spacing $std-side-spacing;
  text-align: center;
  width: 100%;

  .main-text {
    font-weight: bold;
    margin: $std-top-spacing 0;
  }

  .icon-container {
    align-items: center;
    border: 2px solid $color1000-code;
    border-radius: 100%;
    display: flex;
    height: 48px;
    justify-content: center;
    margin: 0 auto;
    width: 48px;

    .icon {
      color: $color100-code;
      height: 26px;
      width: 26px;
    }
  }

}
