@import 'src/layout/assets/css/vars';
@import 'src/layout/assets/css/generic';

%bottom-border-effect {
  //background-image: linear-gradient(to right, $form-border-color 33%, $color0 0%);
  //background-position: bottom;
  //background-repeat: repeat-x;
  //background-size: 3px 1px;
}

.input-wrapper {

  -webkit-box-flex: 1;
  display: flex;

  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  position: relative;

  .form-control {
    overflow: hidden;
    padding-right: 4rem;
  }

  .form-control-extra-buttons {
    opacity: .5;
    position: absolute;
    right: .6rem;
    top: .6rem;

    i {
      @extend %no-select;

      cursor: pointer;
      display: inline;
      padding-left: .5rem;
      padding-right: .2rem;
    }
  }
}

.form-group:focus-within,
.input-wrapper:focus-within {
  .form-control-extra-buttons {
    color: $form-base-color;
    opacity: 1;
  }
}

.empty-value {
  display: none;
}

.compare-mode,
.view-mode {

  &:not(.show-errors) {
    .form-group.error {
      color: $text-regular-color;

      .sub-error {
        display: none;
      }
    }
  }

  .empty-value.form-control {
    display: none;
  }

  .empty-value:not(.form-control) {

    @extend %bottom-border-effect;

    display: block;
    line-height: 36.76px;
    width: 100%;

    &:empty::before {
      content: '-';
      padding-left: 12px;
    }
  }

  .form-control.disabled,
  .form-control:disabled,
  .form-control[readonly] {

    @extend %bottom-border-effect;

    border-style: none;
    color: $text-dark-color;
    -webkit-text-fill-color: $text-dark-color;

    &:placeholder-shown {
      display: none;
    }
  }

  .mandatory .form-label::after {
    display: none;
  }
}


.mandatory .form-label {

  &::after {
    content: '*';
    font-weight: 600;
  }

}
