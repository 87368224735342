@import 'src/layout/assets/css/vars';


.box {

  background-color: $box-content-color;

  box-sizing: border-box;

  font-size: $text-m;

  margin-top: $std-top-spacing;
  padding: $std-top-spacing $std-side-spacing;

  position: relative;

  > div:first-of-type {
    margin-top: 0;
    padding-top: 0;
  }

  &-title {

    //border-bottom: solid 0px $border-box-color;
    color: $text-dark-color;
    font-weight: bold;
    margin: calc(#{$std-top-spacing} * -1) calc(#{$std-side-spacing} * -1) $std-top-spacing;
    padding: 0 $std-side-spacing;

  }

  &-bottom {

    border-top: solid 1px $border-box-color;
    margin: $std-top-spacing calc(#{$std-side-spacing} * -1) 0;

    padding: 15px $std-side-spacing 0;

  }

  &-options {
    display: flex;
    position: absolute;
    right: 15px;

    top: 13px;
    z-index: 2;

    > div {
      margin-left: 5px;
    }

  }

  &-scrolled-content {
    overflow-x: hidden;
    overflow-y: scroll;
  }

  &.collapsed {
    height: 57px;

    .box-title {
      border-bottom: unset;
    }
  }

}
