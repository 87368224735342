@import 'src/layout/assets/css/vars';

.table-advanced-header {

  display: block;
  justify-content: space-between;
  min-height: 40px;
  position: relative;


}
