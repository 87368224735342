@import 'src/layout/assets/css/vars';
@import 'src/layout/assets/css/generic';

.checkbox {

  $box-size: 20px;

  @extend %no-select;

  display: block;
  font-size: $text-s;

  input[type='checkbox'] {
    display: none;
  }

  input[type='checkbox'] + &-box > &-box-icon {
    opacity: 0;
  }

  input[type='checkbox']:checked + &-box > &-box-icon {
    opacity: 1;

  }

  input[type='checkbox']:disabled + &-box {
    opacity: .3;
  }

  &-label {
    display: inline-block;
    vertical-align: middle;

  }

  &-box {
    border: 1px solid $form-base-color;
    border-radius: .25em;
    display: inline-block;
    height: $box-size;

    margin-right: .5em;
    position: relative;
    vertical-align: middle;
    width: $box-size;

    &-icon {
      color: $form-base-color;

      &:not(.square) {
        font-size: calc(#{$box-size} - 6px);
        position: absolute;
        right: 2px;
        top: 2px;
      }

      &.square {
        font-size: calc(#{$box-size} - 4px);
        position: absolute;
        right: 2px;
        top: 1px;
      }


    }
  }

  &.radio {
    .checkbox-box {
      border-radius: 50%;
    }
  }
}



