@import 'src/layout/assets/css/vars';

.modal-buttons {

  border-top: 1px solid $form-border-color;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;

  padding: $std-top-spacing;
  text-align: right;

}
