@import 'src/layout/assets/css/vars';

.how-works {
  background-color: $color0;
  padding: $std-top-spacing 0;


  .main-title {
    color: $text-dark-color;
    font-size: $text-xll;
    margin: $std-top-spacing 0;
  }

  .sub-title {
    color: $color1;
    font-size: $text-xl;
    font-weight: bold;
    margin: $std-light-spacing 0;
  }

  .image-lost {
    display: block;
    margin: $std-top-spacing auto;
    max-width: 90vw;

    @media only screen and (max-width: 768px) {
    }
  }

  .steps {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;

    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }
  }


}
