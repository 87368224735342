@import 'src/layout/assets/css/vars';

.input-group:focus-within,
.focus:not(.error),
.form-group:focus-within:not(.error) {

  color: $form-base-color;

  .input-group-text {
    border-color: $form-base-color;
    color: $form-base-color;

  }

  ::placeholder {
    color: $form-base-color;
  }
}
