@import '../vars';


.modal {
  :not(.box) > .pagination {
    @media only screen and (min-width: 768px) {
      margin: $light-top-spacing calc(#{$std-spacing} * -1) 0;
    }
  }
}

//Pagination
.pagination {

  $height: 43px;

  align-items: center;
  background-color: $box-content-color;
  border-radius: 0;
  display: flex;
  height: $height;
  justify-content: flex-end;
  margin: $light-top-spacing calc(#{$std-side-spacing} * -1) 0;
  padding: 0 $std-side-spacing;

  @media only screen and (max-width: 768px) {
    background-color: $color0;
    margin: 0 calc(#{$mobile-side-spacing} * -1);
    padding: 0 $mobile-side-spacing;

  }


  .pagination-controls {
    align-items: center;
    display: flex;
    justify-content: space-between;

    .disabled {
      opacity: .5;
    }

    @media only screen and (max-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      .previous {
        order: 0;
      }

      .next {
        order: 2;
      }

      .pagination-label {
        order: 1;
      }

    }
  }

  .pagination-label {
    color: $text-dark-color;
    font-size: $text-m;
    padding-right: $std-light-spacing;

  }


  .page-item {

    @media only screen and (max-width: 768px) {

      &:not(.previous):not(.next):not(.pagination-label) {
        display: none;
      }
    }

    .page-link,
    &.disabled .page-link,
    &.active .page-link {
      background-color: $box-content-color;
      font-size: $text-m;

      @media only screen and (max-width: 768px) {
        background-color: $color0;
      }

    }

    &.active .page-link {
      color: $color1;
      font-size: $text-m;
      font-weight: 600;
    }

    .page-link {
      border: 0;
      color: $text-regular-color;
      padding: 0 .3rem;

      &:focus {
        box-shadow: none;
      }
    }

    &:not(.active) {
      font-size: $text-m;
    }
  }


}
