@import 'src/layout/assets/css/vars';

.error {
  .rs {
    .rs__placeholder {
      color: $color100;
    }
  }

}


.rs {

  font-size: $text-m;

  .rs__control--is-focused {

    //box-shadow: unset;

    .rs__placeholder {
      color: $form-base-color;
    }
  }

  .rs__control {

    background-color: $color0;
    border: 1px solid $form-border-color;
    color: $text-regular-color;
    font-size: $text-m;
    border-radius: 2px;


    .rs__multi-value {
      background-color: rgba($color1-code,.14);
      color: $text-regular-color;

      &__label {
        font-size: $text-m;
      }

      &__remove {
        color: $form-base-color;
        cursor: pointer;

        &:hover {
          background-color: transparent;
          color: $color100;
        }
      }
    }


    .rs__single-value {
      color: $text-regular-color;
    }

    .rs__placeholder {
      opacity: .5;
    }


    &:active,
    &:focus,
    &:focus-within {
      border-color: $form-base-color;
      box-shadow: unset;

      .rs__single-value {
        color: $form-base-color;
      }

      .rs__clear-indicator,
      .rs__dropdown-indicator {
        color: $form-base-color;
      }

    }

    &--is-disabled {
      background-color: transparent;


      .rs__value-container {
        opacity: .6;
      }

      .rs__indicators {
        display: none;
      }
    }

  }

  .rs__menu {

    z-index: 5;

    .rs__option {
      color: $text-regular-color;
      font-size: $text-m;

      &--is-selected {
        background-color: $background-color;
        color: $text-dark-color;
      }

      &--is-focused {
        background-color: $form-select-color;
        color: $form-select-text-color;
      }

      &--is-disabled {
        color: rgba($text-regular-color, .3);
      }

    }
  }

  .rs__value-container {
    border-color: $form-base-color;
    color: $form-base-color;
  }
}


.compare-mode,
.view-mode  {

  .error .rs__value-container:not(.rs__value-container--has-value)::after {
    color: $color100;
    content: ' - ';
  }

  .form-group:not(.error) {
    .rs__value-container:not(.rs__value-container--has-value)::after {
      color: $text-regular-color;
      content: ' - ';
    }
  }
}

.compare-mode .rs--is-disabled,
.view-mode .rs--is-disabled {

  .rs__placeholder {
    display: none;
  }

  .rs__control.rs__control--is-disabled,
  .rs.rs--is-disabled {

    //background-image: linear-gradient(to right, $form-border-color 33%, $color0 0%);
    //background-position: bottom;
    //background-repeat: repeat-x;
    //background-size: 3px 1px;
    border-style: none;
    line-height: 1.5rem;


    .rs__value-container {
      opacity: 1;

      &.rs__value-container--has-value {

        .rs__multi-value__remove {
          display: none;
        }

        .rs__multi-value {
          background-color: transparent;
        }

        .rs__single-value {
          color: $text-dark-color;
          -webkit-text-fill-color: $text-dark-color;
        }
      }

    }
  }
}
