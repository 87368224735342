@import 'src/layout/assets/css/vars';

.lost-list-page {
  background-color: $color0;
  min-height: 400px;

  .lost-list-title {
    font-size: $text-xl;
    font-weight: bold;
    padding-top: $std-lg-spacing;
    text-align: center;

    .title-details {
      font-weight: normal;
    }
  }

  .table-content {
    padding: $std-lg-spacing $std-side-spacing;
  }

}


.no-table-results {

  font-size: $text-xll;
  line-height: 600px;
  text-align: center;

}

.exists-not-public {
  font-size: $text-xll;
  padding: $std-lg-spacing;
  text-align: center;
  width: 100%;


}
