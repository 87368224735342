@import 'src/layout/assets/css/vars';

.transponder-input {
  input {
    padding-right: 5rem;
  }
}

.input-wrapper:not(:focus-within),
.form-group:not(:focus-within) {
  .transponder-control-counter {
    display: none;
  }
}

.form-group:focus-within {

  &:not(.error) {
    .transponder-control-counter {
      color: $form-base-color;
    }
  }

  &.error {
    .transponder-control-counter {
      color: $color100;
    }
  }
}


.input-wrapper:focus-within.valid {

  .transponder-control-counter {
    color: $color101;
  }
}

.input-wrapper:focus-within.invalid {

  .transponder-control-counter {
    color: $color11;
    opacity: .3;
  }
}

.input-wrapper:focus-within.exceeded {

  .transponder-control-counter {
    color: $color100;
    opacity: .5;
  }
}
