@import '../vars';


form {
  //Submit button in forms.
  button[type=submit],
  .btn.submit,
  .btn.cancel {
    margin-top: $std-top-spacing;
  }
}

.input-group {
  flex-wrap: nowrap;
}


.form-group {

  color: $text-regular-color;


  &:not(.error) {
    input:-webkit-autofill::first-line,
    input:-webkit-autofill,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:active {
      -webkit-text-fill-color: $text-regular-color;
    }
  }


  &.error,
  &.error .form-text {

    color: $color100;

    input,
    input:-webkit-autofill::first-line,
    input:-webkit-autofill,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:active {
      color: $color100;
      -webkit-text-fill-color: $color100;
    }

    .sub-error {
      display: block;
    }
  }
}

.sub-label,
.sub-error {
  text-align: right;
}

.sub-error {
  display: none;
}

.form-label {
  color: $text-dark-color;
  font-weight: bold;
  margin-bottom: 2px;
  margin-top: 15px;
}

.sub-label {
  color: inherit;
  font-size: 80%;

  a {
    outline: none;
  }

}

.form-control {
  padding: .50rem .75rem;

  &::placeholder {
    opacity: .5;
  }

}

.buttons {
  padding: $std-top-spacing 0 0;
  text-align: right;

}

.btn {
  border-radius: $radius-button-round;
  font-size: $text-m;
  font-weight: 550;
  padding: 10px 30px;
}

.mandatory .form-label {

  &::after {
    content: '*';
    font-weight: 600;
  }

}


.form-control:disabled,
.form-control[readonly] {

  background-color: transparent;
  color: rgba($text-regular-color, .6);
  opacity: 1;
  -webkit-text-fill-color: rgba($text-regular-color, .6);

}
