@import 'src/layout/assets/css/vars';

$light-radius: 10px;

.view-animal {

  background-color: $color0;
  display: flex;
  position: relative;

  @media only screen and (max-width: 900px) {
    flex-direction: column;
  }

  .left-bar {

    background-color: $background-color;
    display: flex;
    flex-direction: column;
    padding: $std-lg-spacing;

    @media only screen and (max-width: 900px) {
      margin-top: $std-top-spacing;
      padding: unset;
    }

    .photo-animal img,
    .photo-animal .no-photo-area {
      border-radius: $radius-input-round;
      height: 400px;
      width: 400px;

      @media only screen and (max-width: 900px) {
        height: 100vw;
        width: 100vw;
      }
    }

    .no-photo-area {
      align-items: center;
      background-color: $color14-code;
      display: flex;
      justify-content: center;

      .no-photo {
        align-items: center;
        color: $text-white-color-code;
        display: flex;
        font-size: 30px;
        font-weight: 600;
        justify-content: center;
        text-transform: uppercase;
      }
    }

    .box-status {
      align-items: center;
      background-color: $color0;
      border-radius: $radius-input-round;
      box-shadow: $box-shadow;
      display: flex;
      flex-direction: column;
      margin-top: -40px;
      padding: $std-spacing;
    }

    .animal-resume {

      padding: $std-spacing;
      width: 100%;


      .title {
        font-size: $text-m;
        margin-top: 15px;
      }
    }

    .facebook-share-button {
      margin: $std-lg-spacing 0 $std-spacing;
    }


  }

  .right-content {

    padding: 0 $std-spacing;

    &.lost {
      .box .box-title {
        border-bottom: 2px solid $color9;
      }
    }

    .box .box-title {
      border-bottom: 2px solid $color3;
      font-size: $text-xl;
      padding-bottom: $std-top-spacing;
      text-transform: uppercase;
    }

    .form-label {
      color: $text-light-color;
      font-size: $text-l;
      font-weight: 550;
    }

    .rs .rs__single-value,
    .form-control {
      font-size: $text-l;
      font-weight: 550;
    }

  }
}




