@import '../vars';

.btn-rounded-primary {

  align-items: center;
  border: 1px solid $color1;
  border-radius: 50%;
  color: $color1;
  cursor: pointer;
  display: flex;

  height: 36px;

  justify-content: center;
  margin: 0;
  padding: 0;

  width: 36px;


  &:hover,
  &:focus,
  &:active,
  &.active,
  &.hover,
  &.focus {
    background-color: $color1;
    color: $color0;
  }

}
